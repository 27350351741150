@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
.swiper-wrapper__clz .swiper-wrapper {
    margin-left: 20px !important;
}

.template-swiper-slide__clz.swiper-slide {
    height: 320px;
    width: 220px !important;
}

.template-swiper-slide-img__clz.swiper-slide img {
    display: block;
    width: 100%;
    height: 80%;
}

.slide-color__clz {
    background-color: rgb(50, 51, 51) !important;
    color: white !important;
}
.slide-No-color__clz {
    background-color: rgb(232, 238, 238) !important;
    color: rgb(10, 10, 10) !important;
}

.advanced-nav-item-active__clz {
    border-color: #e5e5e5 !important;
}

.nav-tabs-active-link__clz.nav-link.active {
    color: rgb(50, 51, 51) !important;
    background-color: unset !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom: 5px solid rgb(50, 51, 51) !important;
}

.sales-methods-nav-link__clz.nav-link {
    color: #121212 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 190px;
    height: 44px;
    text-align: center;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.active-model-item__clz {
    border-bottom: 6px solid rgb(50, 51, 51) !important;
}

.active-model-item-img__clz {
    border-radius: 6px 6px 0px 0px !important;
}

.model-items__clz {
    display: grid;
    grid-template-rows: 230px 20px;
    grid-gap: 5px;
    gap: 5px;
}

.design-active-tab__clz {
    background-color: #eaeaea;
}

.design-container__clz {
    display: grid;
    grid-template-columns: 1100px 460px;
    height: 752px;
    grid-gap: 20px;
    gap: 20px;
}

.swiper-slide-width__clz {
    width: 150px !important;
    margin-left: 10px;
    text-align: center;
    background-color: #e9e9e9;
    white-space: nowrap;
    color: #6a6a6a;
    display: flex;
    justify-content: center;
    align-items: center;
}

.border-wrapper__clz {
    border: 2px solid #0024f9;
    border-radius: 11px;
}

.border-wrapper-banner__clz {
    border: 2px solid #0024f9;
    border-radius: 11px;
    height: 724px;
}

.payment-consummation__clz {
    margin-top: 150px;
}

.nav-link-hover__clz.nav-link {
    border: none !important;
}

input[type="file"] {
    display: none;
}

.file-uploader__clz {
    border: none;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.width__clz {
    text-align: center !important;
    white-space: nowrap !important;
    color: #6a6a6a !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.design-ways-swiper-wrapper__clz .swiper-wrapper {
    height: 36px !important;
}

.design-swiper__clz .swiper-button-prev {
    width: 20px !important;
    height: 5px !important;
    left: 0px !important;
    top: unset !important;
    margin-top: unset !important;
    color: black !important;
}

.design-swiper__clz .swiper-button-prev::after {
    font-size: 20px !important;
}

.design-swiper__clz .swiper-button-next {
    width: 20px !important;
    height: 5px !important;
    right: 0px !important;
    top: unset !important;
    margin-top: unset !important;
    color: black !important;
}

.design-swiper__clz .swiper-button-next::after {
    font-size: 20px !important;
}

.design-container-items__clz {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 100%;
}

.design-tab-nav-tabs-active-link__clz.nav-link.active {
    color: rgb(50, 51, 51) !important;
    background-color: #e6e6e6 !important;
    border-color: unset !important;
    border: none !important;
    border-left: 6px solid rgb(50, 51, 51) !important;
}

.nav-link__clz.nav-link {
    color: #121212 !important;
    width: 100%;
    height: 44px;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.switch-items__clz {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    gap: 10px;
}

.switch-items-with-sub-steps__clz {
    display: grid;
    grid-template-columns: 0.5fr 0.1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.languages-table__clz {
    font-size: 15px !important;
}

.languages-table__clz.table th {
    font-weight: normal !important;
    padding: 1px;
    max-height: 45px !important;
}

.final-message-table__clz.table th {
    font-weight: normal;
    padding: 1px;
    max-height: 55px !important;
}

.languages-table__clz table {
    width: 100%;
}

.languages-table__clz.table td {
    padding: 0.4rem !important;
}

.languages-table__clz thead tbody,
tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 45px !important;
}

.languages-table__clz tbody {
    display: block;
    overflow: hidden auto;
    table-layout: fixed;
    max-height: 114px;
}

.languages-table__clz table td {
    padding: 0.5rem !important;
}

.custom-design-button__clz {
    height: 38px !important;
    width: 130px !important;
    min-width: unset !important;
    min-height: unset !important;
}

.main-content-width__clz {
    max-width: 400px;
}

.main-content-has-sub-step__clz {
    grid-gap: 25px;
    gap: 25px;
}
.swiper-button__clz:hover {
    border: none !important;
}

.slide-color__clz {
    background-color: rgb(50, 51, 51) !important;
    color: white !important;
}

:root {
    --page-background: white;
    --scrollbar-track: transparent;
    --scrollbar-thumb: rgb(191, 193, 186);
    --scrollbar-thumb-hover: rgb(110, 108, 108);
    --scrollbar-width: 12px;
}

* {
    scrollbar-width: auto;
    scrollbar-color: rgb(191, 193, 186) transparent;
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

*::-webkit-scrollbar {
    width: 12px;
    width: var(--scrollbar-width);
    height: 12px;
    height: var(--scrollbar-width);
}

*::-webkit-scrollbar-track {
    background: transparent;
    background: var(--scrollbar-track);
}

*::-webkit-scrollbar-thumb {
    background: rgb(191, 193, 186);
    background: var(--scrollbar-thumb);
    border-radius: 12px;
    border-radius: var(--scrollbar-width);
    border: calc(12px / 4) solid white;
    border: calc(var(--scrollbar-width) / 4) solid var(--page-background);
}

*::-webkit-scrollbar-thumb:hover {
    background: rgb(110, 108, 108);
    background: var(--scrollbar-thumb-hover);
}

textarea {
    outline-color: #719ece;
}

.table__clz .table-responsive {
    display: flex;
}

.personlized-content__clz {
    overflow-y: scroll;
    scrollbar-width: none;
}

.personlized-content__clz::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.loyalty-languages-table__clz {
    font-size: 15px;
}

.loyalty-languages-table__clz.table th {
    font-weight: normal;
    padding: 1px;
    max-height: 45px !important;
}

.loyalty-languages-table__clz table {
    width: 100%;
}

.loyalty-languages-table__clz.table td {
    padding: 0.4rem !important;
}

.loyalty-languages-table__clz thead tbody,
tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 45px !important;
}

.loyalty-languages-table__clz tbody {
    display: block;
    overflow: hidden auto;
    table-layout: fixed;
}

.loyalty-languages-table__clz table td {
    padding: 0.5rem !important;
}

.payment-amount-table__clz thead tbody,
tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 39px !important;
}

.payment__clz thead th {
    font-size: 0.6em;
    padding: 1px !important;
    height: 15px;
}
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button;
    opacity: 0;
}

input[type="number"]::-webkit-inner-spin-button:hover {
    -webkit-appearance: inner-spin-button;
    opacity: 1;
    margin-left: 1px;
}

.input-container {
    margin-bottom: 10px;
}

.input-container.focused input {
    border-color: blue;
}

.width__clz {
    text-align: center;
    background-color: #e9e9e9;
    white-space: nowrap;
    color: #6a6a6a;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap !important;
    cursor: pointer !important;
    width: 100px;
    height: 34px;
}

.design-ways-swiper-wrapper__clz .swiper-wrapper {
    height: 36px !important;
}

.design-swiper__clz .swiper-button-prev {
    width: 20px !important;
    height: 5px !important;
    left: 0px !important;
    top: unset !important;
    margin-top: unset !important;
    color: black !important;
}

.design-swiper__clz .swiper-button-prev::after {
    font-size: 20px !important;
}

.design-swiper__clz .swiper-button-next {
    width: 20px !important;
    height: 5px !important;
    right: 0px !important;
    top: unset !important;
    margin-top: unset !important;
    color: black !important;
}

.design-swiper__clz .swiper-button-next::after {
    font-size: 20px !important;
}

.design-container-items__clz {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 100%;
}

.design-tab-nav-tabs-active-link__clz.nav-link.active {
    color: rgb(50, 51, 51) !important;
    background-color: #e6e6e6 !important;
    border-color: unset !important;
    border: none !important;
    border-left: 6px solid rgb(50, 51, 51) !important;
}

.nav-link__clz {
    color: #121212 !important;
    width: 100%;
    height: 44px;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.switch-items__clz {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    gap: 10px;
}

.switch-items-with-sub-steps__clz {
    display: grid;
    grid-template-columns: 0.5fr 0.1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.languages-table__clz {
    font-size: 15px;
}

.languages-table__clz.table th {
    font-weight: normal;
    padding: 1px;
    max-height: 45px !important;
}

.final-message-table__clz.table th {
    font-weight: normal;
    padding: 1px;
    max-height: 55px !important;
}

.languages-table__clz table {
    width: 100%;
}

.languages-table__clz.table td {
    padding: 0.4rem !important;
}

.languages-table__clz thead tbody,
tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 45px !important;
}

.languages-table__clz tbody {
    display: block;
    overflow: hidden auto;
    table-layout: fixed;
    max-height: 114px;
}

.languages-table__clz table td {
    padding: 0.5rem !important;
}

.custom-design-button__clz {
    height: 38px !important;
    width: 130px !important;
    min-width: unset !important;
    min-height: unset !important;
}

.main-content-width__clz {
    max-width: 400px;
}

.main-content-has-sub-step__clz {
    grid-gap: 25px;
    gap: 25px;
}
.swiper-button__clz:hover {
    border: none !important;
}

.disabled-sub-step-slide__clz .nav-link {
    background-color: #ffffff;
    color: #323333;
}

.disable-nav-tabs-border-bottom.nav-tabs {
    border: none;
}

.general-container__clz {
    height: 100vh;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "nav main";
}

.non-collapsed-container__clz {
    grid-template-columns: 250px auto;
}

.collapsed-container__clz {
    grid-template-columns: 72px auto;
}

.d-grid__clz {
    display: grid !important;
}

.cursor__clz {
    cursor: default;
}

.modal-option-content__clz .modal-content {
    width: 1300px !important;
    height: 1000px !important;
    margin-left: none;
}

.template-container__clz {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1.9fr 0.1fr;
}

.w-40__clz {
    width: 40%;
}

.h-40__clz {
    height: 40%;
}

.w-30__clz {
    width: 30%;
}

.h-30__clz {
    height: 30%;
}

.config-options-icon__clz {
    height: 2em;
    width: 2em;
    fill: white;
}

.template-swiper-container__clz.swiper-container {
    width: 455px;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}

.language-swiper-slide__clz.swiper-slide {
    width: 33% !important;
    margin-right: 20px !important;
}

.language-swiper-slide-img__clz.swiper-slide img {
    display: block;
    width: 100%;
    object-fit: contain;
}

.title-language__clz {
    font-size: 15px;
    font-weight: bold;
}
.kiosk-languages-swiper-container__clz .swiper-wrapper {
    align-items: end;
}

.keyboard__clz.hg-theme-default.hg-layout-numeric .hg-button {
    font: normal normal bold 23px/27px Arial;
    align-items: center;
    display: flex !important;
    justify-content: center !important;
    font: normal normal bold 23px/27px Arial;
    justify-content: center !important;
    width: 38px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000052;
    border-radius: 10px;
}

.keyboard__clz.hg-theme-default .hg-button span {
    width: 6px;
    height: 14px;
    text-align: center;
    font: normal normal bold 12px/14px Arial;
    letter-spacing: 0px;
    color: #000000;
}

.simple-keyboard {
    max-width: 400px;
}

.keyboard__clz.hg-theme-default {
    background-color: transparent !important;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px;
    touch-action: manipulation;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

.keyboard__clz.hg-theme-default .hg-button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: center;
    padding: 5px;
}

.centredItems__clz {
    display: flex;
    justify-content: center;
    align-items: center;
}

.calorie {
    width: 100%;
    font: normal 400 12px/16px;
    font-family: Segoe UI;
    grid-gap: 10px;
    gap: 10px;
    min-height: 24px;
}
.price {
    font: normal bold 24px/29px Segoe UI;
    min-height: 35px;
}

.product-image {
    width: 50px;
}
.button__clz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70px;
    grid-gap: 10px;
    gap: 10px;
}
.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
}

:root {
    --height-workflow-header: 105px;
    --swiper-width: 0px;
}

.workflow-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 105px;
    background: transparent linear-gradient(180deg, #ffffff 0%, #ed1c2482 100%)
        0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px #00000029;
    opacity: 1;
}

.workflow-product-title {
    height: 60px;
    text-align: left;
    font: normal normal bold 45px/60px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
}

.workflow-tree {
    width: 10px;
    height: 55px;
    background: #ffffff 0% 0% no-repeat padding-box;
}
.workflow-footer {
    bottom: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.workflow-basket {
    width: 100%;
    height: auto;
    background: #f8f8f8 0% 0% no-repeat padding-box;
}

.workflow-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.drop-item-button {
    width: 266px;
    height: 65px;
    border: 1px solid #05fee1;
    border-radius: 38px;
}

.drop-item-button-text {
    text-align: center;
    font: normal normal 600 25px/33px Segoe UI;
    letter-spacing: 0px;
    color: #6a92ff;
}

.workflow-total {
    text-align: center;
    font: normal normal 600 29px/38px Segoe UI;
    letter-spacing: 0px;
    color: #1f1f1f;
}

.workflow-timeline {
    /* margin-top: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.workflow-main-step-title {
    font: normal normal 600 25px/33px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    padding-left: 5px;
    text-transform: lowercase;
}

.workflow-main-step-title:first-letter {
    text-transform: uppercase;
}

.workflow-step-title {
    font: normal normal 600 20px/27px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    padding-left: 5px;
    text-transform: lowercase;
}

.workflow-step-title:first-letter {
    text-transform: uppercase;
}

.workflow-step {
    background: #ededed 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    height: 40px;
}

.workflow-main-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.workflow-swiper-slide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    grid-gap: 15px;
    gap: 15px;
}

.workflow-swiper-container .swiper-wrapper {
    height: 200px !important;
    padding-top: 0.25rem !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    gap: 5px;
}

.workflow-swiper-container-items-fewer-than-four .swiper-wrapper {
    transition-duration: 0ms !important;
    transform: translate3d(0px, 0px, 0px) !important;
}

.workflow-swiper-container.swiper-container {
    height: 300px !important;
    width: 0px !important;
    width: var(--swiper-width) !important;
    margin-left: unset !important;
    display: flex;
    align-items: center;
    margin-right: unset !important;
}

.step-item {
    border-radius: 50%;
    background-color: white;
    padding: 10px 13px;
    color: #00000052;
    transition: border-color color;
    text-align: center;
    height: 39px;
    width: 39px;
    outline: 3px solid var(--progress-color);
    outline-offset: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 19px/60px Segoe UI;
    box-shadow: inset 0px 0px 3px #0000000f, 0px 2px 6px #00000052;
    position: relative; /* absolute*/
    padding: unset !important;
}

.outer {
    background-color: #0000001c;
    height: 39px;
    width: 39px;
    position: relative;
    z-index: -3;
    top: 8px;
    left: 4px;
    animation: waves 1.5s infinite ease-in-out;
}

.check {
    display: inline-block;
    transform: rotate(45deg);
    height: 24px;
    width: 12px;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
}

.workflow-swiper-slide::after {
    content: "";
    width: 105px;
    height: 10px;
    background-color: white;
    position: absolute;
    top: 84px;
    left: 37px;
    box-shadow: inset 0px 0px 3px #0000000f, 0px 2px 6px #00000052;
    z-index: 1;
}

.workflow-swiper-slide:last-child::after {
    content: "";
    background-color: transparent;
    width: 0px;
}

.active-line {
    width: 53px;
    height: 25px;
    border-bottom: 3px solid var(--progress-color);
    position: absolute;
    top: 65.5px;
    left: 34px;
    z-index: 2;
    animation: lineActiveTrans 1s linear;
}

.completed-line {
    width: 225px;
    height: 47px;
    border-bottom: 3px solid #000000;
    position: absolute;
    top: 182px;
    left: 73px;
    z-index: 2;
    animation: lineCompletedTrans 2s normal forwards;
}

@keyframes lineActiveTrans {
    0% {
        width: 0px;
        /*transform: translateX(-50%);*/
    }
    50% {
        width: 70px;
    }
    100% {
        width: 106px;
        /* transform: translateX(50%); */
    }
}

@keyframes lineCompletedTrans {
    0% {
        width: 106px;
        /*transform: translateX(-50%);*/
    }

    50% {
        width: 180px;
    }

    100% {
        width: 225px;
        /* transform: translateX(50%); */
    }
}

@keyframes waves {
    to {
        box-shadow: 0 0 0 3px #0000000d, 0 0 0 6px #00000005, 0 0 0 10px #0000,
            0 0 0 6px #00000024;
    }
}

.inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    height: 30px;
    width: 30px;
    position: relative;
    border-radius: 50%;
}

.left-position {
    left: 20px;
}

.h-110 {
    height: 110px;
}

.non-active-step {
    outline: unset;
    z-index: 2;
}

.top-button-position {
    position: relative;
    top: 50px;
}

.step-image {
    grid-gap: 15px;
    gap: 15px;
    height: 144px;
    padding-right: 20px;
}

.w-1068 {
    width: 1068px;
}

.arrow-right,
.arrow-left {
    display: block;
    margin: 30px auto;
    width: 25px;
    height: 25px;
    border-top: 5px solid gray;
    border-left: 5px solid gray;
}

.arrow-right {
    transform: rotate(135deg);
}

.arrow-left {
    transform: rotate(-45deg);
    margin-right: 20px;
}

.circle {
    border-radius: 50%;
}

.icons-button__clz {
    all: unset;
}

.header-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 425px;
    height: 30px;
    box-shadow: 0px -5px 6px #2c2c2c15;
    border-bottom: 1px solid rgba(112, 112, 112, 0.23);
}
.cart-icon {
    width: 40px;
    height: 70px;
    text-align: center;
}
.number-articles {
    display: inline-block;
    margin-left: 15px;
    font: normal normal 300 9px/18px Segoe UI;
}

.label-open-cart {
    position: absolute;
    top: 53%;
    left: 40%;
}
.cart-pmr-button {
    display: flex;
    width: auto;
    height: 58px;
    justify-content: flex-end;
}
.help-icon {
    cursor: pointer;
}
.pmr-icon {
    cursor: pointer;
}
.footer-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 425px;
    text-align: center;
    font: normal normal 600 10px/24px Segoe UI;
    letter-spacing: 0px;
    color: #b7b7b7;
    opacity: 1;
}
.footer-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(112, 112, 112, 0.23);
    opacity: 0.23;
    opacity: 1;
    height: 97px;
}
.cancel-order {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 258px;
    height: 73px;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #8b8b8b;
    opacity: 1;
}
button[disabled="disabled"],
button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
    animation-name: disableButtonAnimation;
    animation-duration: 0.3s;
}
@keyframes disableButtonAnimation {
    0% {
        opacity: 1;
        cursor: pointer;
        pointer-events: auto;
    }
    100% {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }
}
.done-order {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 760px;
    height: 73px;
    background: #ff8205 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    opacity: 1;
}
.label-done-order {
    width: 76px;
    height: 29px;
    text-align: center;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}
.total-order {
    width: 150px;
    height: 29px;
    text-align: right;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}

/***** end cart style ****/

/***** popup cart style ********/
.header-popup {
    position: absolute;
    z-index: 1000;
    width: 1080px;
    background: white;
    top: 0;
    /* padding-top: 50px; */
    height: 200px;
}

.footer-button-popup-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(112, 112, 112, 0.23);
    opacity: 0.23;
    opacity: 1;
    height: 97px;
    position: fixed;
    bottom: 0;
    padding-left: 7px;
    padding-right: 7px;
    width: 1080px;
    background-color: #ffffff;
}

.cancel-order-popup-card {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 258px;
    height: 73px;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #8b8b8b;
    opacity: 1;
    margin-left: 10px;
}
.done-order-popup-card {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 770px;
    height: 73px;
    margin-right: 20px;
    background: #ff8205 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    opacity: 1;
}
.label-close-cart {
    position: absolute;
    top: 53%;
    left: 40%;
}
.title-popup-cart {
    font: normal normal 600 58px/70px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: center;
    height: 58px;
    margin-top: 80px;
}
.number-poroduct-in-cart {
    text-align: center;
    font: normal normal normal 28px/34px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 20px;
    height: 28px;
}
.simple-product {
    position: relative;
    margin: 0 auto;
    margin-bottom: 44px;
    width: 982px;
    min-height: 319px;
    /* UI Properties */
    background: #fdfdfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000080;
    border: 1px solid #f8f8f6;
    opacity: 1;
    border-radius: 15px;
}
.product-header {
    margin-top: 18px;
    margin-left: 28px;
    margin-right: 28px;
    margin-right: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.number-product {
    text-align: center;
    font: normal normal 600 40px/48px Segoe UI;
    letter-spacing: 0px;
    color: #ff8205;
    display: inline-block;
    margin-right: 15px;
}
.product-title {
    font: normal normal 600 40px/48px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
}
.price-product {
    font: normal normal 600 35px/42px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    text-transform: uppercase;
    opacity: 1;
    margin-left: 103px;
}
.product-info-container {
    height: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.product-info {
    display: flex;
}
.list-compositions {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    padding-top: 30px;
    margin-left: 25px;
}
.list-compositions > div {
    flex: 1 1 150px;
    text-align: left;
    font: normal normal normal 23px/28px Segoe UI;
    letter-spacing: 0px;
    color: #0d0c0c;
    opacity: 1;
}
.view-more {
    position: absolute;
    right: 7px;
    bottom: 19px;
    border: 0px;
    cursor: pointer;
    align-self: flex-end;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 157px;
    height: 43px;
    background: #ff8205 0% 0% no-repeat padding-box;
    border-radius: 15px;
    font: normal normal normal 17px/20px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
}
.imag-product {
    height: 155px;
    width: 116px;
    margin-left: 70px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.imag-product-component {
    height: 108px;
    width: 133px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.imag-icon-product {
    height: 65px;
    width: 65px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.edit-product {
    height: 93px;
    align-self: center;
    margin-bottom: 10px;
}
.label-edit-product {
    font: normal normal bold 16px/22px Nunito;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.product-operations > * {
    cursor: pointer;
}
.product-operations {
    display: flex;
}
.details-component {
    width: 565px;
}
.cart-products-list {
    max-height: 1626px;
    overflow-y: auto;
    padding-top: 250px;
}
.product-component {
    height: 217px;
    width: 888px;
    margin: 0 auto;
    margin-top: 34px;
}
.product-component:last-of-type {
    margin-bottom: 117px;
}
.info-component {
    display: flex;
    width: 888px;
    height: 170px;
    padding-top: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 15px;
    opacity: 1;
}
.info-container-product {
    height: 144px;
}
.info-container-product .title-product-preview {
    margin-top: 23px;
}
.simple-product-operations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    margin: unset;
}
.number-components {
    text-align: center;
    margin-right: 10px;
    font: normal normal bold 30px/36px Segoe UI;
    letter-spacing: 0px;
    color: #ff8205;
    opacity: 1;
}
.component-name {
    font: normal normal bold 30px/36px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 8px;
}
.basic-compositions-component {
    font: normal normal normal 17px/20px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 8px;
}
.variants-component {
    width: 500px;
    /* UI Properties */
    font: normal normal normal 18px/22px Segoe UI;
    color: #3cc73a;
}
.title-component {
    font: normal normal bold 35px/47px Nunito;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
/*** end style for popup cart *****/
/*** swiperproduct cart ***/
.swiper-product-cart {
    width: 1050px;
    height: 218px;
}
.product-swiper {
    width: 334px;
    height: 214px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
}
.imag-product-preview {
    height: 84px;
    width: 112px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 10px;
    margin-top: 5px;
}
.info-image-price {
    display: flex;
    justify-content: space-between;
    width: 309px;
}
.price-product-preview {
    font: normal normal bold 22px/26px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 15px;
}
.title-product-preview {
    font: normal normal bold 20px/24px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
    text-align: left;
    margin-top: 5px;
    margin-left: 20px;
}
.compositions-product-preview {
    font: normal normal 300 15px/18px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-left: 20px;
}
.actions-product-preview {
    display: flex;
    justify-content: flex-end;
    border-top: 5px solid #f5f5f5;
    width: "140px";
}
.view-more-product-preview {
    border: 0px;
    cursor: pointer;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 50px;
    background: #ff8205 0% 0% no-repeat padding-box;
    border-radius: 15px;
    font: normal normal normal 17px/20px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    align-self: center;
}
.number-product-preview {
    font: normal normal bold 22px/26px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    align-self: center;
}

/*** end swiper product ****/
.productContainer__clz {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1.1fr;
    grid-template-rows: 1.6fr 0.3fr 0.3fr 0.1fr 1fr;
}

.centredItems__clz {
    display: flex;
    justify-content: center;
    align-items: center !important;
}

/*** test ***/

@keyframes fadeInDown {
    0% {
        z-index: -10;
        transform: translateY(-55%);
    }
    20% {
        transform: translateY(-60%);
    }
    90% {
        transform: translateY(5%);
    }
    100% {
        z-index: 0;
        transform: translateY(0);
    }
}

.category-swiper-slide__clz {
    transform: translateY(0);
    animation-name: fadeInSlideDown;
    animation-duration: 3s;
}

@keyframes fadeInSlideDown {
    0% {
        opacity: 0.5;
    }
    /* 50% {
        opacity: 0.7;
    } */
    100% {
        opacity: 1;
    }
}

.category-swiper__clz {
    transform: translateY(0);
    animation-name: fadeInDown;
    animation-duration: 2s;
}

@keyframes fadeInDownSlideElement {
    0% {
        opacity: 0.15;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.product-swiper {
    animation-name: fadeInDownSlideElement;
    animation-duration: 1.5s;
}

.category__clz {
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
}

.category-card__clz {
    width: 57px;
    height: 56px;
    border-radius: 9px;
    box-shadow: 0px 3px 6px #00000029;
}

.category-swiper__clz.swiper-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.category-swiper__clz {
    transform: translateY(0);
    animation-name: fadeInDown;
    animation-duration: 2s;
}

.active-category-card__clz {
    width: 57px;
    height: 56px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    border-bottom: var(--primary-color) 2px solid;
    position: relative;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.active-category-card__clz::after {
    content: "";
    background: transparent
        linear-gradient(180deg, #ffffff00 50%, var(--primary-color) 100%) 0% 0%
        no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    border-bottom: var(--primary-color) 2px solid;
    position: absolute;
    width: 66px;
    height: 55px;
    top: 0;
    left: 0;
    opacity: 0.3;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.category-title__clz {
    font: normal normal bold 16px/21px Segoe UI;
    text-align: center;
    width: 70px;
    letter-spacing: 0px;
    color: #343434;
    text-transform: uppercase;
}

.active-category-title__clz {
    font: normal normal bold 10px/15px Segoe UI;
    text-align: center;
    width: 80%;
    letter-spacing: 0px;
    color: #343434;
    text-transform: uppercase;
}

.centredItems__clz {
    display: flex;
    justify-content: center;
    align-items: center;
}

.calorie {
    width: 100%;
    font: normal 400 12px/16px;
    font-family: Segoe UI;
    grid-gap: 10px;
    gap: 10px;
    min-height: 24px;
}
.price {
    font: normal bold 24px/29px Segoe UI;
    min-height: 35px;
}

.product-image {
    width: 50px;
}
.button__clz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70px;
    grid-gap: 10px;
    gap: 10px;
}
.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
}

.tags-swiper-width__clz {
    height: 23px !important;
    background: #33333326 0% 0% no-repeat padding-box;
    border-radius: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content !important;
    width: max-content !important;
    padding: 0 10px !important;
}

.active-tags-swiper-width__clz {
    height: 23px !important;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content !important;
    width: max-content !important;
    padding: 0 10px !important;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tags-swiper__clz.swiper-container {
    width: calc(calc(100vh - 210px) / 2);
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: unset !important;
    margin-right: unset !important;
    height: 10px;
}

.tags-swiper__clz .swiper-wrapper {
    align-items: center;
    width: calc(calc(100vh - 200px) / 2);
    grid-gap: 5px;
    gap: 5px;
    z-index: -1;
}

.headerSummary {
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000080;
    border-radius: 19px;
    height: 180px;
    width: 380px;
    margin-top: 11px;
    margin-left: 23px;
}

.centered-items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.column-centered-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cart-logo {
    height: 181px;
    width: 181px;
}

.header-text {
    display: flex;
    flex-direction: column;
    padding-left: 2px;
}

.summary-cart-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    scrollbar-color: gray #e0e0e0;
}

.footer-summary-cart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-content: flex-start;
    padding-left: 3px;
    padding-right: 3px;
    height: 180px;
}

.first-block-footer-summary-cart {
    display: flex;
    padding-right: 3px;
    height: 96px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shopping-cart {
    height: 87px;
    width: 87px;
}

.config-option {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.sales-method-items {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.vertical-separation-line {
    height: 60px;
    width: 1px;
    background-color: #cccccc;
}

.option-config-items {
    height: 65px;
    width: 65px;
    box-shadow: 0px 3px 6px #00000080;
    border-radius: 15px;
}
.summary-actions {
    display: flex;
    height: 96px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
}

.first-button {
    height: 73px;
    width: 435px;
    font: normal normal 600 27px/36px Segoe UI;
    border-radius: 15px;
}

.pay-button {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    width: 591px;
    height: 73px;
    border-radius: 15px;
    font: normal normal bold 30px/41px Nunito;
    color: #ffffff;
}

.text-confirmed-alert {
    text-align: center;
    height: 334px;
    font-size: 45px;
    grid-gap: 10px;
    gap: 10px;
}

.basic-compositions-component {
    font: normal normal normal 10px/10px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 8px;
}

.variants-component {
    /* UI Properties */
    font: normal normal normal 9px/8px Segoe UI;
    color: #3cc73a;
}

.container-wrapper {
    display: grid;
    grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content 1fr 200px;
    grid-template-rows: min-content min-content min-content 1fr 200px;
    width: 100%;
    height: 96%;
    padding: none;
}

.fade-enter {
    opacity: 0;
}

.fade-exit {
    opacity: 1;
}

.fade-enter-active {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 500ms;
}

/* slide enter */
.slide-enter {
    opacity: 0;
    transform: scale(0.6) translateY(100%);
}

.slide-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.slide-exit {
    opacity: 1;
    transform: translateY(0);
}

.slide-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
}

/* rotate enter */
.rotate-enter {
    transform: rotateY(90deg) rotateX(45deg);
}
.rotate-enter-active {
    transform: rotateY(0deg) rotateX(0deg);
    transition: transform 1s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.rotate-exit {
    transform: rotateY(0deg) rotateX(0deg);
}
.rotate-exit-active {
    transform: rotateY(-50deg) rotateX(-20deg);
}

/* left to right */
.left-to-right-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.left-to-right-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.left-to-right-exit {
    opacity: 1;
    transform: translateX(0);
}

.left-to-right-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
}

/* right to left*/
.right-to-left-enter {
    opacity: 0;
    transform: translateX(100%);
}

.right-to-left-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.right-to-left-exit {
    opacity: 1;
    transform: translateX(0);
}

.right-to-left-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
}

/* top to bottom */
.top-to-bottom-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.top-to-bottom-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}
.top-to-bottom-exit {
    opacity: 1;
    transform: translateY(0);
}
.top-to-bottom-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 300ms, transform 300ms;
}

.menu__clz {
    width: 0 !important;
    z-index: 1001;
    background: #ffffff;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 70px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.dropdown-menu {
    min-width: 5rem !important;
}
.language-switch {
    top: 5px !important;
    left: 12px !important;
}
.header_wrapper__clz {
    position: fixed !important;
}
.styled_h1__clz {
    width: 71px !important;
}
.footer__clz {
    margin-left: 65px !important;
    display: flex;
    position: fixed;
}
.mobile__clz {
    margin-left: 0px !important;
    display: flex;
}
.search__clz {
    display: d-none;
}
.search-icon__clz {
    fill: #161617 !important;
    position: absolute;
    z-index: 10;
    left: 10px;
    width: 20px !important;
}
.vertical-menu__clz {
    z-index: 1001;
    background: #ffffff;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 70px;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    text-align: left;
    display: inline-block;
}
.main-content {
    margin-left: 274px;
    overflow: hidden;
}
.footer {
    position: fixed;
    right: 0;
    color: #212529;
    left: 250px !important;
    background-color: #efeff5;
    display: flex;
}

.navbar-brand-box__clz {
    width: 273px !important;
}
.main-content__clz {
    margin-left: 272px !important;
    overflow: hidden !important;
}

.footerSettings__clz {
    position: fixed;
    right: 0;
    color: #212529;
    left: 1px;
    background-color: #efeff5;
    display: flex;
}

.line1__clz {
    font-size: 18px;
    font-weight: bold;
}

.line2__clz {
    font-size: 14px;
}

.logo__clz {
    position: absolute;
    left: 18px;
    top: 22px;
}
.smallLogo__clz {
    position: absolute;
    left: 21px;
    top: 22px;
}

ul.sub-menu > li.mm-active > a.active {
    border-left: 3px solid white;
    background-color: #ecefef24;
}

.link-div__clz {
    border-left: 1px solid #1f3dd0;
    padding-left: 9px;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
}

.col-icon-notification__clz {
    background-color: #292929;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.text-notification__clz {
    font: normal normal normal 20px/27px Segoe UI;
}

.card-header__clz,
.card-footer__clz {
    padding: 0px !important;
    background-color: transparent !important;
}

.message-info__clz {
    display: none !important;
}
.notification-text__clz {
    font: normal normal normal Segoe UI;
    font-size: 1rem;
}

.button-style-select___clz {
    height: 36px !important;
    min-height: 30px !important;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

#TablePagination {
    min-height: 64px;
    z-index: 1;
}
.Card-footer__clz {
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}
.card-monetic_associate__clz {
    margin: 1px !important;
    border: 1px solid rgb(220, 220, 220) !important;
    height: auto;
}
.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

#TablePagination {
    min-height: 64px;
    z-index: 1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
}

.swal-content {
    margin-top: 25px !important;
}

.dark-bg__clz {
    background: #292929 !important;
}

.active-card-theme__clz {
    background: #464647 !important;
    box-shadow: 0px 14px 23px -2px rgba(0, 0, 0, 0.29) !important;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.not-allowed-icon-check__clz {
    pointer-events: none;
}

.preview-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: -webkit-left;
}

.Toastify__toast-container--top-center {
    width: 50%;
}

.Toastify__toast-body {
    height: 0px;
}

.page-content__clz {
    padding: calc(40px + 24px) calc(24px / 2) 0px calc(6px / 2);
    background-color: #f3f3f9;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.not-allowed-icon-check__clz {
    pointer-events: none;
}

.button-style-select___clz {
    height: 36px !important;
    min-height: 30px !important;
}

.form-check-label__clz .form-check-label {
    margin-top: 10px !important;
}
.page-scroll__clz {
    /* overflow: scroll !important; */
    max-height: 100vh;
    max-width: 100vw;
}
.page-scroll__clz::-webkit-scrollbar {
    display: none;
}
.btn-footer__clz {
    width: 30%;
    max-width: 30%;
}

.responsiveModal__clz {
    padding: 0px !important;
}
.fullscreen {
    max-width: 100% !important;
    max-height: 100% !important;
}

.active-tab__clz {
    color: black !important;
}
.border-footer__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin: 0px !important;
}

.modal-dialog-printer__clz .modal-content {
    height: 280px !important;
}

.border-footer-maintenace__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 23px !important;
}
.border-footer-inventar__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 51px !important;
}
.active-tab__clz {
    border-bottom: 3px solid black !important;
}
.input-file___clz {
    width: 72%;
    height: 29px !important;
}

.text-spinner__clz {
    width: 70px !important;
    height: 30px !important;
    font-size: 16px;
    border-radius: 10px !important;
    -moz-appearance: textfield !important;
    text-align: center;
}
.border-footer-Valina__clz {
    border-top: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.spinner-btn__clz {
    min-width: 10px !important;
    min-height: 10px !important;
    border-radius: 24px !important;
    margin: 3px 5px 3px 1px !important;
    height: 25px;
    width: 24px;
}

.form-group {
    margin-bottom: 0px !important;
}

.btn_upload__clz {
    min-width: 40px;
    min-height: 25px;
    position: relative;
    top: 25px;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.row__clz {
    padding-right: 11px;
    padding-left: 11px;
    border-radius: 9px;
}
.card-footer__clz {
    background-color: white !important;
}
.card-body__clz {
    padding: 0px !important;
}
.card__clz {
    box-shadow: 0 0.75rem 0rem rgb(251 251 251 / 0%) !important;
}
.body_modal__clz {
    min-height: 700px;
}
#input-spinner-right-button {
    border-radius: 49px;
    width: 29px;
}
#input-spinner-left-button {
    border-radius: 49px;
    width: 29px;
}
.input-group:not(.has-validation) > .form-control:not(:last-child) {
    width: 117px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 6px !important;
    text-align: center;
    font-size: 13px;
}

.custom-modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.input-styled__clz {
    width: 286px !important;
}
.input-qr-styled__clz {
    width: 288px !important;
    margin-left: 91px !important;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
    width: 16px;
    height: 15px;
}
.modal-wrapper-width__clz .modal-content {
    width: 95vw !important;
    height: auto !important;
}

.border-footer-state__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin: 0px !important;
}
.display-none__clz {
    display: none !important;
}

.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #000 !important;
    border-color: #74788d !important;
}
.card__clz {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgb(220, 220, 220);
    margin-bottom: 7px !important;
}
.card-modal-component__clz {
    display: grid;
    grid-auto-columns: 7.8fr 0.8fr;
    grid-template-rows: 1fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    /* min-height: 658px; */
}
.card_modale__clz {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1.5fr 1fr;
    grid-column-gap: 0px;
    column-gap: 0px;
    border: 2px none;
}
.card-model-first__clz {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 1px;
    border: 1px solid #eff2f7 !important;
    background-color: #eaeaea;
    height: 587px;
}
.card-model-second__clz {
    margin: 1px !important;
    border: 1px solid #eff2f7 !important;
    max-height: 587px !important;
}
.border__clz {
    border-bottom: 1px solid rgb(220, 220, 220);
}
.style-btn-model__clz {
    width: 18%;
    border-color: white;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
}
.style-model__clz {
    font-weight: bold !important;
}
.style-span__clz {
    font-weight: bold !important;
}
.style-logo__clz {
    height: 50vh;
}
.Styled-button__clz {
    width: 10px;
}
.Card-footer__clz {
    background-color: white;
}
.btn-model__clz:focus {
    background: black;
    color: white;
}
.btn-model__clz:after {
    background: black;
    color: white;
}
.check-style__clz {
    width: 30px;
    height: 31px;
    background: #333131 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #bcbcbc;
    border-radius: 18px;
    margin-bottom: 530px;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1b1b1c;
    border-color: #74788d;
}

.style-card-button-model {
    height: 60px;
    border: 1px solid #c6c6c6;
    opacity: 1;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    background-color: #ffffffbf !important;
    border-radius: unset;
    padding: 0.5rem 1rem;
    padding-bottom: 0.5rem;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #ffffff;
}

.menu-wrapper-nav-link__clz {
    color: white;
    width: auto !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
li.menu-wrapper-items-nav-link-active__clz.active {
    background: #f8f9faa3;
    width: 100%;
}
a.menu-wrapper-items-nav-link-active__clz.active {
    color: whitesmoke !important;
    opacity: 1 !important;
    border-left: 2px solid whitesmoke !important;
}
.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.active-tab__clz {
    border-bottom: 2px solid #000;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.not-allowed-customer-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.active-tab__clz {
    color: black !important;
}
.border-footer__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin: 0px !important;
}
.border-footer-maintenace__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 23px !important;
}
.border-footer-inventar__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 51px !important;
}
.active-tab__clz {
    border-bottom: 3px solid black !important;
}
.input-file___clz {
    width: 72%;
    height: 29px !important;
}

.text-spinner__clz {
    width: 70px !important;
    height: 30px !important;
    font-size: 16px;
    border-radius: 10px !important;
    -moz-appearance: textfield !important;
    text-align: center;
}
.border-footer-Valina__clz {
    border-top: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.spinner-btn__clz {
    min-width: 10px !important;
    min-height: 10px !important;
    border-radius: 24px !important;
    margin: 3px 5px 3px 1px !important;
    height: 25px;
    width: 24px;
}

.form-group {
    margin-bottom: 0px !important;
}

.btn_upload__clz {
    min-width: 40px;
    min-height: 25px;
    position: relative;
    top: 25px;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.row__clz {
    padding-right: 11px;
    padding-left: 11px;
    border-radius: 9px;
}
.card-footer__clz {
    background-color: white !important;
}
.card-body__clz {
    padding: 0px !important;
}
.card__clz {
    box-shadow: 0 0.75rem 0rem rgb(251 251 251 / 0%) !important;
}
.body_modal__clz {
    min-height: 700px;
}
#input-spinner-right-button {
    border-radius: 49px;
    width: 29px;
}
#input-spinner-left-button {
    border-radius: 49px;
    width: 29px;
}
.input-group:not(.has-validation) > .form-control:not(:last-child) {
    width: 117px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 6px !important;
    text-align: center;
    font-size: 13px;
}

.custom-modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.input-styled__clz {
    width: 286px !important;
}
.input-qr-styled__clz {
    width: 288px !important;
    margin-left: 91px !important;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
    width: 16px;
    height: 15px;
}
.modal-wrapper-width__clz .modal-content {
    width: 95vw !important;
    height: auto !important;
}

.border-footer-state__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin: 0px !important;
}
.display-none__clz{
    display:none !important;
}
.Toastify__toast-container--top-center {
    width: 50%;
}

.Toastify__toast-body {
    height: 0px;
}

.page-content__clz {
    padding: calc(40px + 24px) calc(24px / 2) 0px calc(6px / 2);
    background-color: #f3f3f9;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.not-allowed-icon-check__clz {
    pointer-events: none;
}

.button-style-select___clz {
    height: 36px !important;
    min-height: 30px !important;
}

.form-check-label__clz .form-check-label {
    margin-top: 10px !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.not-allowed-icon-check__clz {
    pointer-events: none;
}

.form-check-label {
    margin-top: 3px;
    margin-bottom: 4px;
    font-size: 17px;
}
.num__clz {
    height: 40px;
    width: 290px;
    text-align: center;
    align-items: center;
    color: black;
    /* background: linear-gradient(to left, red 50%, green 50%); */
}

span.react-numeric-input b {
    display: none;
}

.border-footer-empty__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
.modal-content__clz{
    height: 310px;
    width: 80%;


}
.th-costum-table__clz {
    background: #f8f8fb !important;
    font-weight: 700 !important;
    padding: 0px 0px 0px 12px !important;
    border: 0.5px solid #e0e0e0 !important;
}
.MuiTablePagination-displayedRows {
    margin: 0px !important;
}

.MuiTablePagination-selectLabel {
    margin: 0px !important;
}
.costum-table-cell__clz {
    padding: 2px 2px 2px 10px !important;
}
table.costumTable > tbody > tr {
    height: 21px !important;
    min-height: 21px !important;
}
tfoot.MuiTableFooter-root > tr {
    height: 30px !important;
}

.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
    min-height: 40px !important;
    padding-right: 2px !important;
}
.dropdown-costum-table__clz {
    position: fixed !important;
    top: auto !important;
    left: 98vh !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 9999999999999 !important;
}
.MuiTablePagination-displayedRows {
    display: none !important;
}
.disabled-label__clz {
    pointer-events: none;
}
table.costumTable > thead.MuiTableHead-root > tr {
    height: 10px !important;
}

.table-drp-button__clz {
    padding: 1px !important;
}
.tab-content.m-3 {
    height: 100%;
    min-height: 100%;
    margin: 0px !important;
}
.tab-content.m-3 > .tab-pane.active {
    height: 100%;
}

.bg-chart__clz {
    background: #f6fbfb !important;
}

/* .modal-body {
    height: 500px;
} */

.placeholder__clz:-ms-input-placeholder {
    text-align: center;
}
.placeholder__clz::placeholder {
    text-align: center;
}

.input-cash__clz {
    top: 147px;
    left: 321px;
    width: 218px;
    height: 27px;
    text-align: left;
    font: normal normal 600 20px/24px Segoe UI;
    color: #000000;
    opacity: 1;
    margin-left: 12px;
}

.spinnerpercent ::before {
    z-index: 100000;
    content: "%";
    position: absolute;
    right: 49px;
    top: 10px;
    font-size: 15px;
}

.numInput__clz {
    width: 130px;
    height: 30px;
    text-align: center;
}

.required__clz:after {
    content: " *";
    color: red;
}
#dropdown-split-variants-Success {
    border-radius: 18px;
    width: 100px;
    font-size: 13px;
}
#mytable {
    max-height: 610px;
}
#TablePagination {
    min-height: 51px;
    z-index: 1;
}
.modal-assoc__clz > .modal-content {
    max-height: 89vh !important;
}
.btn__clz {
    background: #f8f8fb 0% 0% no-repeat padding-box;
    border: 1px solid #e5e4e4;
    opacity: 1;
}
.card {
    margin-bottom: 0px !important;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.style-btn__clz {
    width: 100%;
    border-color: white;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
}

.device-btn__clz {
    width: 100%;
    border-color: white;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: #323333;
}

.MuiCheckbox-root {
    cursor: pointer !important;
    color: black !important;
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.not-allowed-icon-check__clz {
    pointer-events: none;
}

.pointer__clz {
    cursor: pointer;
    color: black !important;
}
.custom-modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}

@media (min-width: 0px) {
    @media (orientation: landscape) {
        .css-i6s8oy {
            min-height: 74px !important;
        }
    }
}
.css-hyum1k-MuiToolbar-root {
    min-height: 74px !important;
}

.custom-modal-style_clz {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}
.button-style-select___clz {
    height: 36px !important;
    min-height: 30px !important;
}
.modal-assoc__clz > .modal-content {
    max-height: 89vh !important;
}

.page-card-carte:hover .page-content_Carte {
    background: transparent linear-gradient(180deg, #3a3a3a 0%, #5b5b5b 100%) 0%
        0% no-repeat padding-box !important;
    box-shadow: 0px 5px 10px #00000042;
    border: 1px solid #dbdbdb;
    border-radius: 20px;
    opacity: 1;
}
.page-card-carte:hover .card-title {
    color: #ffffff !important ;
}
.page-card-carte:hover .card-text {
    color: #ffffff !important ;
}
.page-card-carte :hover .select_carte {
    opacity: 0 !important ;
}
.home-page-content__clz {
    height: 93vh !important ;
}
.page-card-carte :hover.icon {
    fill: #cf080842 !important ;
    stop-color: #cf080842 !important ;
    background: transparent
        linear-gradient(180deg, #cf080842 0%, #cf080842 100%) 0% 0% no-repeat
        padding-box !important;
}
@media (max-width: 1894px) {
    .T-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
.page-content-Home__clz {
    margin-left: 0px !important;
    overflow: hidden !important;
}

.arrow-button__clz:hover {
    background-color: transparent !important;
    border: none !important;
}

.item-index__clz {
    display: grid;
    grid-template-columns: 0.2fr 1.8fr;
    font-size: 23px;
    box-shadow: 0px 3px 6px #00000029;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.margin-unset__clz.inznaY {
    margin: 0px !important;
}

.fullscreen-bg-color__clz {
    background-color: #363636;
}

.escape-fullscreen-mode-button__clz {
    background-color: red !important;
    z-index: 1;
    border-top-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

.escape-fullscreen-mode-button__clz:hover {
    border: none !important;
}

.checkbox__clz {
    width: 18px;
    height: 18px;
}

.table__orb__clz thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #dee2e6 !important;
    border-top: 0px solid #dee2e6 !important;
}

.checkbox__clz {
    width: 18px;
    height: 18px;
}

.cell_divided__clz {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    position: relative;

    background-image: linear-gradient(
        to right top,
        white 49%,
        #cccfd3 50%,
        white 51%
    );
}

.cell_topRight__clz {
    grid-column-start: 2;
    text-align: right;
    margin: auto;
}

.cell_bottomLeft__clz {
    grid-column-start: 1;
    margin: auto;
}

.bordered_table_clz th {
    margin: 0;
    padding: 0 !important;
    position: relative;
    min-height: 40px;
    border: 1px solid #cccfd3;
}
.bordered_table_clz td {
    margin: 0;
    padding: 0 !important;
    position: relative;
    border: 1px solid #cccfd3;
}

.centered-input:-ms-input-placeholder {
    text-align: center;
}

.centered-input::placeholder {
    text-align: center;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}

.title__clz {
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 50px;
    margin-bottom: 10px;
    position: center;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.uploader__clz {
    height: 200px;
    width: 454px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1em !important;
}
.flag__clz {
    font-size: 3em;
    line-height: 1em;
    margin-left: 1rem;
}
#TablePagination {
    min-height: 64px;
    z-index: 1;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(395deg);
    }
}
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #b6b2b2 !important;
    width: 120px;
    height: 120px; /* Safari */
    animation: spin 2s linear infinite;
    animation-duration: 1s;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.not-allowed-input__clz div {
    pointer-events: none;
    opacity: 0.8;
    background-color: #c7cdcd !important;
}
#TablePagination {
    min-height: 64px;
    z-index: 1;
}
.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}
.cercle {
    width: 64px;
    height: 40px;
    border-radius: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #00000029;
    border: 1px solid #000;

    opacity: 1;
}

.cercleactive {
    width: 64px;
    height: 40px;
    border-radius: 20px;
    background: black 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #ffffff;
    color: red;

    border: 1px solid #000;
    opacity: 1;
}
.badge-success-service__clz {
    background: #34c38f 0% 0% no-repeat padding-box !important;

    font: normal normal normal 16px/21px Segoe UI !important;
    letter-spacing: 0px !important;
    color: black !important;
    width: 175px !important;
    height: 34px !important;
    text-align: center !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badge-warning__clz {
    color: #212529;
    background-color: #ffc107;
    width: 175px !important;
    height: 34px !important;
    text-align: center !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
    font: normal normal normal 12px/17px Segoe UI !important;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.line-1 {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
    padding-top: 11px;
    margin-top: 11px;
  
}
card-service__clz{
     width: 450px !important;
}

#TablePagination {
    min-height: 64px;
    z-index: 1;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}
.popup-css_clz {
    max-width: 508px !important;
    width: 508px !important;
}
.not-allowed-input-vr__clz {
    pointer-events: none;
    opacity: 0.8;
    background-color: #c7cdcd !important;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.not-allowed-input__clz {
    pointer-events: none;
    opacity: 0.6;
}

.css-1wa3eu0-placeholder {
    font-size: 14px;
}

.checkbox__clz {
    width: 18px;
    height: 18px;
}

.cell_divided__clz {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    position: relative;

    background-image: linear-gradient(
        to right top,
        white 49%,
        #cccfd3 50%,
        white 51%
    );
}

.cell_topRight__clz {
    grid-column-start: 2;
    text-align: right;
    margin: auto;
}

.cell_bottomLeft__clz {
    grid-column-start: 1;
    margin: auto;
}

.bordered_table_clz th {
    margin: 0;
    padding: 0 !important;
    position: relative;
    min-height: 40px;
    border: 1px solid #cccfd3;
    
}
.bordered_table_clz td {
    margin: 0;
    padding: 0 !important;
    position: relative;
    border: 1px solid #cccfd3;
}
#TablePagination {
    min-height: 64px;
    z-index: 1;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.MuiBox-root.css-0 {
    max-height: calc(100vh - 631px) !important;
}

.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.MuiTableRow-root{
    min-height: 60px !important;

}
.MuiTableCell-root{
    min-height: 60px !important;

}
.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

#TablePagination {
    min-height: 64px;
    z-index: 1;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.MuiBox-root.css-0 {
    max-height: calc(100vh - 631px) !important;
}

.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}




@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.container__clz {
    height: 255px;
    width: 454px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1em !important;
}
.item {
    width: 10em;
}

#TablePagination {
    min-height: 64px;
    z-index: 1;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
.ant-upload.ant-upload-select-picture-card {
    width: 429px;
    height: 103px;
    margin-right: 0px;
    margin-bottom: 0px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
}
.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
.ant-upload.ant-upload-select-picture-card {
    width: 429px;
    height: 103px;
    margin-right: 0px;
    margin-bottom: 0px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
}
.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.not-allowed-input__clz {
    pointer-events: none;
    opacity: 0.6;
}

.css-1wa3eu0-placeholder {
    font-size: 14px;
}

.width__clz {
    width: 150px;
}

#TablePagination {
    min-height: 64px;
    z-index: 1;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.MuiBox-root.css-0 {
    max-height: calc(100vh - 631px) !important;
}

.checkbox_fiscalYear__clz {
    width: 20px;
    height: 20px;
    accent-color: black;
}

.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.background {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 4000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(4, 4, 4, 0.5);
}

.click-capturer {
    display: inline-block;
    position: relative;
    height: 940px;
    margin: 15px;
    margin-top: 20vh;
}

/* .close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
} */

.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}
.modal-wrapper-width__clz .modal-content {
    
    width: 95vw !important;
}

.modal-dialog__clz.modal-dialog {
    max-width: 660px !important;
}

.modal-dialog__clz .modal-content {
    height: 260px !important;
}

.cancel-btn-hover__clz:hover {
    background-color: #e30613 !important;
    border-color: #e30613 !important;
}

.customer-account-menu-nav-link-active__clz.nav-link.active {
    color: rgb(14, 14, 15) !important;
    border: none !important;
}

.customer-account-menu-nav-link-disable__clz.nav-link {
    border: none !important;
    color: rgb(14, 14, 15) !important;
}

.active-nav-item__clz {
    border-bottom: 3px solid rgb(11, 11, 11);
}

.disable-nav-item__clz.nav-link {
    border-bottom: 1px solid #ddd4d4 !important;
}

.index-shadow__clz {
    box-shadow: 0px 3px 6px #00000029;
}

.disable-account-creation-items__clz {
    pointer-events: none;
    opacity: 0.36;
}
.reset__clz {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}

.config-main-content__clz {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr auto;
    grid-template-areas: "title" "drag-list";
}

.nav-item-active__clz {
    background-color: rgba(0, 0, 0, 0.429);
}

.template-card__clz:hover {
    box-shadow: -2px 2px 12px -3px rgba(0, 0, 0, 0.5);
}

.wrapper__clz {
    font-family: "Nunito Sans", sans-serif !important;
}

.invalid__clz {
    border-color: #eb2020 !important;
    border-style: solid;
    border-width: 1px;
}

.disable-account-creation-items__clz {
    pointer-events: none;
    opacity: 0.36;
}

.drag-list-wrapper__clz {
    grid-area: drag-list;
    display: grid;
    grid-template-rows: 55px auto;
}

.draggable-element-wrapper__clz {
    display: grid;
    grid-template-columns: 200px auto;
    height: 100%;
}

.ways-item__clz {
    display: grid;
    grid-template-rows: 568px 65px;
    grid-template-columns: 315px;
    border-radius: 1rem;
}

.elements-item__clz {
    display: grid;
    grid-template-rows: 1.9fr 0.1fr;
}

.ways-item-index__clz {
    display: grid;
    grid-template-columns: 0.2fr 1.8fr;
    font-size: 16px;
    box-shadow: 0px 3px 6px #00000029;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.close-button__clz {
    background-color: red !important;
    z-index: 1;
    border-top-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

.close-button__clz:hover {
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: none !important;
}

.drag-drop-wrapper__clz > div {
    justify-content: center;
    grid-gap: 100px;
    gap: 100px;
}

.cercle__clz {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #00000029;
    border: 1px solid #000;

    opacity: 1;
}
.cercleactive__clz {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: black 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #ffffff;
    color: red;

    border: 1px solid #000;
    opacity: 1;
}

.menu-wrapper-nav-link__clz {
    color: white;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 190px;
    height: 44px;
    text-align: justify;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: unset !important;
    border-color: unset !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}

.menu-wrapper-nav-link__clz {
    color: white;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 190px;
    height: 44px;
    text-align: justify;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: unset !important;
    border-color: unset !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: auto;
}
li.menu-wrapper-items-nav-link-active__clz.active {
    background: #f8f9faa3;
}
a.menu-wrapper-items-nav-link-active__clz.active {
    color: whitesmoke !important;
    opacity: 1 !important;
    border-left: 2px solid whitesmoke !important;
}
.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.card {
    margin-bottom: 3px !important;
}
.custom-modal-style_clz {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.badge-secondary_clz {
    color: #808080 !important;
    background-color: antiquewhite !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badge-success__clz {
    color: #20b021 !important;
    background-color: #b1eac0 !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badge-pill-secondary__clz {
    padding-right: 3.6em !important;
    padding-left: 2.6em !important;
}
.badge-danger {
    color: #ff0000 !important;
    background-color: #ffe2e2 !important;
}
.badge-style_clz {
    color: #8811f9 !important;
    background-color: #ebdffe !important;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4 !important;
}

.style-button-planing__clz:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #8811f9 !important;
    background-color: #ebdffe !important;
    border-color: #8811f9 !important;
}
.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}
.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.style-table__clz {
    display: grid;
    min-height: 650px;
    grid-template-columns: auto;
    grid-column-gap: 4px;
    border: 0;
}
.modal-wrapper__clz .modal-content {
    height: 92vh !important;
    width: 95vw !important;
}
.modal-wrapper-width__clz .modal-content {
    width: 95vw !important;
}


.border-Footer__clz {
    border-bottom: 1px solid #ced4da;
}
.card_cl__clz {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-column-gap: 4px;
    column-gap: 4px;
    border: 0;
}
.style-card_clz {
    border-color: #ced4da !important;
    border-width: thin;
}
.card_c__clz {
    display: grid;
    grid-template-rows: 0.5fr 1fr;
    grid-column-gap: 4px;
    column-gap: 4px;
    border: 0;
}
.fieldsetInterne__clz {
    border: 1px solid #dedede;
    border-radius: 5px;
    opacity: 1;
}

.react-datepicker__clz {
    width: 73px !important;
    height: 30px;
    font-size: 14px;
}
.style-modal__clz {
    width: 650px;
    min-width: 700px;
}

.btn-style__clz:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem white !important;
}
.style-calender__clz {
    min-width: 200px !important;
    width: 200px !important;
}
.react-datepicker__header {
    text-align: center;
    background-color: white !important;
    border-bottom: 1px solid #f0e7e7;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}
.react-datepicker__month-container {
    width: 320px;
    max-width: 320px;
    overflow: auto;
    height: 274px;
    max-height: 274px;
}
.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 1rem;
    color: #000;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #0c0a09 !important;
    color: #fff;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}
.not-allowed-input__clz {
    pointer-events: none;
    opacity: 0.8;
    background-color: #c7cdcd !important;
}
#TablePagination {
    min-height: 64px;
    z-index: 1;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.MuiBox-root.css-0 {
    max-height: calc(100vh - 631px) !important;
}
.checkbox_fiscalYear__clz {
    width: 20px;
    height: 20px;
    accent-color: black;
}

.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.container__clz {
    height: 255px;
    width: 454px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1em !important;
}
.item {
    width: 10em;
}

.modal-wrapper-hours__clz .modal-content {
    height: 80vh !important;
    width: 35vw !important;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.hex_drp_FRdefineRecurrences {
    height: 20px;
    width: 90%;
}
#TablePagination {
    min-height: 64px;
    z-index: 1;
}
.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
.ant-upload.ant-upload-select-picture-card {
    width: 429px;
    height: 103px;
    margin-right: 0px;
    margin-bottom: 0px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
}
.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.not-allowed-fidelity__clz {
    pointer-events: none;
    opacity: 0.6;
}

.css-1wa3eu0-placeholder {
    font-size: 14px;
}

.width__clz {
    width: 150px;
}

.new-password-toggle_fidelity__clz {
    position: absolute;
    padding-top: 7px;
    right: 78px;
    opacity: 0.6;
}

input.ant-input:-ms-input-placeholder {
    color: #6c757d !important;
    opacity: 1;
}

input.ant-input::placeholder {
    color: #6c757d !important;
    opacity: 1;
}

#TablePagination {
    min-height: 64px;
    z-index: 1;
}



.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.MuiBox-root.css-0 {
    max-height: calc(100vh - 631px) !important;
}

.checkbox_fiscalYear__clz {
    width: 20px;
    height: 20px;
    accent-color: black;
}

.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
.information_fidelity__clz {
    width: 551px !important;
    height: 625px !important;
}
.information_fidelity_modal__clz {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    width: 0px !important;
    pointer-events: none !important;
    background-color: transparent !important;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
    margin-left: 709px;
    margin-right: 516px;
    margin-bottom: 0px;
}
.not-allowed-fidelity__clz {
    pointer-events: none;
    opacity: 0.8;
    background-color: white !important;
}

#nprogress .bar,
#nprogress .spinner {
    z-index: 999999 !important;
}
.password-toggle__2 {
    position: absolute;
    padding-top: 18px !important;
    right: 26px;
    opacity: 0.6;
}
.password-toggle__1 {
    position: absolute;
    right: 26px;
    opacity: 0.6;
}
.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}

@media (max-width: 576px) {
    .login-logo__clz {
        margin-left: 23vw;
        margin-top: 2vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }

    .etk-logo__clz {
        top: 5%;
        position: absolute;
        transform: scale(0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
                clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto !important;
        height: 100vh !important;
    }
    .login-card {
        top: 60% !important;
        left: 50% !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .login-logo__clz {
        margin-left: 28vw;
        margin-top: 2vh;
        margin-bottom: 4vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }
    .etk-logo__clz {
        width: 80%;
        top: 2%;
        left: 10%;
        position: absolute;
        transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
                clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto !important;
        height: 100vh !important;
    }
    .login-card {
        top: 55% !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto !important;
        height: 100% !important;
    }
    .login-card {
        top: 60% !important;
        left: 55% !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto;
        height: 100vh;
    }
    .login-card {
        top: 55% !important;
        left: 55% !important;
    }
}

@media (min-width: 1200px) {
    .login-logo__clz {
        margin-left: 4vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 10vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        top: 5%;
        left: 4%;
        position: absolute;
        transform: translate(0%, 15%);
        width: 30%;
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 40vw;
    }
    .video {
        width: 120%;
        height: auto;
    }

    .login-card {
        left: 60% !important;
    }
}

.login-btn__clz {
    box-shadow: 0px 15px 20px 7px #bfbdbd !important;
}

.login-btn__clz:hover {
    background-color: white !important;
    color: black !important;
}

.login-card {
    font-family: "Poppins";
    left: 60% !important;
    top: 50% !important;
    width: 30% !important;
    height: 47% !important;
}

.login-card h3 {
    font-size: 35px;
}

.login-card .form-group label {
    font-size: 20px;
}

.password-toggle__clz {
    position: absolute;
    padding-top: 42px;
    right: 35px;
    opacity: 0.6;
}

.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}
.submit-button__clz {
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    margin-left: 31%;
}

@media (min-width: 1700px) {
    .forgot-text__clz {
        position: fixed;
        bottom: 0;
        margin-bottom: 18%;
        margin-left: 31%;
    }
}
@media (max-width: 1700px) {
    .forgot-text__clz {
        position: fixed;
        bottom: 0;
        margin-bottom: 25%;
        margin-left: 31%;
    }
}

@media (max-width: 576px) {
    .update-logo__clz {
        margin-left: 23vw;
        margin-top: 2vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }

    .etk-logo__clz {
        top: 5%;
        position: absolute;
        transform: scale(0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
                clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto !important;
        height: 100vh !important;
    }
    .update-card__clz {
        top: 60% !important;
        left: 50% !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .update-logo__clz {
        margin-left: 28vw;
        margin-top: 2vh;
        margin-bottom: 4vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }
    .etk-logo__clz {
        width: 80%;
        top: 2%;
        left: 10%;
        position: absolute;
        transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
                clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto !important;
        height: 100vh !important;
    }
    .update-card__clz {
        top: 55% !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .update-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto !important;
        height: 100% !important;
    }
    .update-card__clz {
        top: 60% !important;
        left: 55% !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .update-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto;
        height: 100vh;
    }
    .update-card__clz {
        top: 55% !important;
        left: 55% !important;
    }
}

@media (min-width: 1200px) {
    .update-logo__clz {
        margin-left: 4vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 10vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        top: 5%;
        left: 4%;
        position: absolute;
        transform: translate(0%, 15%);
        width: 30%;
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 40vw;
    }
    .video {
        width: 120%;
        height: auto;
    }

    .update-card__clz {
        left: 60% !important;
    }
}

.update-btn__clz {
    box-shadow: 0px 15px 20px 7px #bfbdbd !important;
}

.update-btn__clz:hover {
    background-color: white !important;
    color: black !important;
}

.update-card__clz {
    font-family: "Poppins";
    left: 60% !important;
    top: 55% !important;
    width: 30% !important;
    height: 67% !important;
}

.update-card__clz h3 {
    font-size: 35px;
}

.update-card__clz .form-group label {
    font-size: 20px;
}

.password-toggle__clz {
    position: absolute;
    padding-top: 42px;
    right: 35px;
    opacity: 0.6;
}

.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}
.submit-button__clz {
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    margin-left: 31%;
}

input[type='radio'] {
  -webkit-appearance:none;
  width:15px;
  height:15px;
  border:1px solid black;
  border-radius:50%;
  outline:none;
  box-shadow:0 0 5px 0px white;
}


input[type='radio']:before {
  content:'';
  display:block;
  width:60%;
  height:60%;
  margin: 20% auto;    
  border-radius:50%;    
}
input[type='radio']:checked:before {
  background:black;
}
.style-input-select__clz{
    width: 200px !important
}
.style-button-order__clz.btn-secondary{
    color:transparent;
    background-color:transparent;
    border-color:transparent
}
.style-button-order__clz.btn-secondary:active{
    color:transparent;
    background-color:transparent;
    border-color:transparent
}
.form-control-input-zone__clz{
width: 150px !important;
height: 40px;
padding: .375rem .75rem;
  padding-right: 0.75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
}
.form-control-input__clz{
  width: 150px !important;
  height: 40px;
  padding: .375rem .75rem;
    padding-right: 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  }


.menu-wrapper-nav-link__clz {
    color: white;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 190px;
    height: 44px;
    text-align: justify;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: unset !important;
    border-color: unset !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}
.style-buttom__clz{
    background-color: currentColor !important
}

.active-tab__clz {
    color: black !important;
}
.border-footer__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin: 0px !important;
}
.border-footer-maintenace__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 23px !important;
}
.border-footer-inventar__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 51px !important;
}
.active-tab__clz {
    border-bottom: 3px solid black !important;
}
.input-file___clz {
    width: 72%;
    height: 29px !important;
}

.text-spinner__clz {
    width: 70px !important;
    height: 30px !important;
    font-size: 16px;
    border-radius: 10px !important;
    -moz-appearance: textfield !important;
    text-align: center;
}
.border-footer-Valina__clz {
    border-top: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.spinner-btn__clz {
    min-width: 10px !important;
    min-height: 10px !important;
    border-radius: 24px !important;
    margin: 3px 5px 3px 1px !important;
    height: 25px;
    width: 24px;
}

.form-group {
    margin-bottom: 0px !important;
}

.btn_upload__clz {
    min-width: 40px;
    min-height: 25px;
    position: relative;
    top: 25px;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.row__clz {
    padding-right: 11px;
    padding-left: 11px;
    border-radius: 9px;
}
.card-footer__clz {
    background-color: white !important;
}
.card-body__clz {
    padding: 0px !important;
}
.card__clz {
    box-shadow: 0 0.75rem 0rem rgb(251 251 251 / 0%) !important;
}
.body_modal__clz {
    min-height: 700px;
}
#input-spinner-right-button {
    border-radius: 49px;
    width: 29px;
}
#input-spinner-left-button {
    border-radius: 49px;
    width: 29px;
}
.input-group:not(.has-validation) > .form-control:not(:last-child) {
    width: 117px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 6px !important;
    text-align: center;
    font-size: 13px;
}

.custom-modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.input-styled__clz {
    width: 286px !important;
}
.input-qr-styled__clz {
    width: 288px !important;
    margin-left: 91px !important;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
    width: 16px;
    height: 15px;
}
.modal-wrapper-width__clz .modal-content {
    width: 95vw !important;
    height: auto !important;
}

.border-footer-state__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin: 0px !important;
}

.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #000 !important;
    border-color: #74788d !important;
}
.card__clz {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgb(220, 220, 220);
    margin-bottom: 7px !important;
}
.card-modal-component__clz {
    display: grid;
    grid-auto-columns: 7.8fr 0.8fr;
    grid-template-rows: 1fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    /* min-height: 658px; */
}
.card_modale__clz {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1.5fr 1fr;
    grid-column-gap: 0px;
    column-gap: 0px;
    border: 2px none;
}
.card-model-first__clz {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 1px;
    border: 1px solid #eff2f7 !important;
    background-color: #eaeaea;
    height: 587px;
}
.card-model-second__clz {
    margin: 1px !important;
    border: 1px solid #eff2f7 !important;
    max-height: 587px !important;
}
.border__clz {
    border-bottom: 1px solid rgb(220, 220, 220);
}
.style-btn-model__clz {
    width: 18%;
    border-color: white;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
}
.style-model__clz {
    font-weight: bold !important;
}
.style-span__clz {
    font-weight: bold !important;
}
.style-logo__clz {
    height: 50vh;
}
.Styled-button__clz {
    width: 10px;
}
.Card-footer__clz {
    background-color: white;
}
.btn-model__clz:focus {
    background: black;
    color: white;
}
.btn-model__clz:after {
    background: black;
    color: white;
}
.check-style__clz {
    width: 30px;
    height: 31px;
    background: #333131 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #bcbcbc;
    border-radius: 18px;
    margin-bottom: 530px;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1b1b1c;
    border-color: #74788d;
}

.style-card-button-model {
    height: 60px;
    border: 1px solid #c6c6c6;
    opacity: 1;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    background-color: #ffffffbf !important;
    border-radius: unset;
    padding: 0.5rem 1rem;
    padding-bottom: 0.5rem;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #ffffff;
}

.menu-wrapper-nav-link__clz {
    color: white;
    width: auto !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
li.menu-wrapper-items-nav-link-active__clz.active {
    background: #f8f9faa3;
    width: 100%;
}
a.menu-wrapper-items-nav-link-active__clz.active {
    color: whitesmoke !important;
    opacity: 1 !important;
    border-left: 2px solid whitesmoke !important;
}
.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.active-tab__clz {
    border-bottom: 2px solid #000;
}

.style-table__clz {
    display: grid;
    min-height: 650px;
    grid-template-columns: auto;
    grid-column-gap: 4px;
    border: 0;
}
.modal-wrapper__clz .modal-content {
    height: 92vh !important;
    width: 95vw !important;
}
.modal-wrapper-width__clz .modal-content {
    width: 95vw !important;
}


.active-tab__clz {
    color: black !important;
}
.border-footer__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin: 0px !important;
}
.border-footer-maintenace__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 23px !important;
}
.border-footer-inventar__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 51px !important;
}
.active-tab__clz {
    border-bottom: 3px solid black !important;
}
.input-file___clz {
    width: 72%;
    height: 29px !important;
}

.text-spinner__clz {
    width: 70px !important;
    height: 30px !important;
    font-size: 16px;
    border-radius: 10px !important;
    -moz-appearance: textfield !important;
    text-align: center;
}
.border-footer-Valina__clz {
    border-top: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.spinner-btn__clz {
    min-width: 10px !important;
    min-height: 10px !important;
    border-radius: 24px !important;
    margin: 3px 5px 3px 1px !important;
    height: 25px;
    width: 24px;
}

.form-group {
    margin-bottom: 0px !important;
}

.btn_upload__clz {
    min-width: 40px;
    min-height: 25px;
    position: relative;
    top: 25px;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.row__clz {
    padding-right: 11px;
    padding-left: 11px;
    border-radius: 9px;
}
.card-footer__clz {
    background-color: white !important;
}
.card-body__clz {
    padding: 0px !important;
}
.card__clz {
    box-shadow: 0 0.75rem 0rem rgb(251 251 251 / 0%) !important;
}
.body_modal__clz {
    min-height: 700px;
}
#input-spinner-right-button {
    border-radius: 49px;
    width: 29px;
}
#input-spinner-left-button {
    border-radius: 49px;
    width: 29px;
}
.input-group:not(.has-validation) > .form-control:not(:last-child) {
    width: 117px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 6px !important;
    text-align: center;
    font-size: 13px;
}

.custom-modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.input-styled__clz {
    width: 286px !important;
}
.input-qr-styled__clz {
    width: 288px !important;
    margin-left: 91px !important;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
    width: 16px;
    height: 15px;
}
.modal-wrapper-width__clz .modal-content {
    width: 95vw !important;
    height: auto !important;
}

.border-footer-state__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin: 0px !important;
}

.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #000 !important;
    border-color: #74788d !important;
}
.card__clz {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgb(220, 220, 220);
    margin-bottom: 7px !important;
}
.card-modal-component__clz {
    display: grid;
    grid-auto-columns: 7.8fr 0.8fr;
    grid-template-rows: 1fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    /* min-height: 658px; */
}
.card_modale__clz {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1.5fr 1fr;
    grid-column-gap: 0px;
    column-gap: 0px;
    border: 2px none;
}
.card-model-first__clz {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 1px;
    border: 1px solid #eff2f7 !important;
    background-color: #eaeaea;
    height: 587px;
}
.card-model-second__clz {
    margin: 1px !important;
    border: 1px solid #eff2f7 !important;
    max-height: 587px !important;
}
.border__clz {
    border-bottom: 1px solid rgb(220, 220, 220);
}
.style-btn-model__clz {
    width: 18%;
    border-color: white;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
}
.style-model__clz {
    font-weight: bold !important;
}
.style-span__clz {
    font-weight: bold !important;
}
.style-logo__clz {
    height: 50vh;
}
.Styled-button__clz {
    width: 10px;
}
.Card-footer__clz {
    background-color: white;
}
.btn-model__clz:focus {
    background: black;
    color: white;
}
.btn-model__clz:after {
    background: black;
    color: white;
}
.check-style__clz {
    width: 30px;
    height: 31px;
    background: #333131 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #bcbcbc;
    border-radius: 18px;
    margin-bottom: 530px;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1b1b1c;
    border-color: #74788d;
}

.style-card-button-model {
    height: 60px;
    border: 1px solid #c6c6c6;
    opacity: 1;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    background-color: #ffffffbf !important;
    border-radius: unset;
    padding: 0.5rem 1rem;
    padding-bottom: 0.5rem;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #ffffff;
}

.menu-wrapper-nav-link__clz {
    color: white;
    width: auto !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
li.menu-wrapper-items-nav-link-active__clz.active {
    background: #f8f9faa3;
    width: 100%;
}
a.menu-wrapper-items-nav-link-active__clz.active {
    color: whitesmoke !important;
    opacity: 1 !important;
    border-left: 2px solid whitesmoke !important;
}
.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.active-tab__clz {
    border-bottom: 2px solid #000;
}

.bg-chart__clz {
    background: #f6fbfb !important;
}

.placeholder__clz:-ms-input-placeholder {
    text-align: center;
}
.placeholder__clz::placeholder {
    text-align: center;
}

.form-check-label {
    margin-top: 3px;
    margin-bottom: 4px;
    font-size: 17px;
}
.num__clz {
    height: 40px;
    width: 290px;
    text-align: center;
    align-items: center;
    color: black;
}

span.react-numeric-input b {
    display: none;
}

.border-footer-empty__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
.modal-content__clz{
    height: 310px;
    width: 80%;


}
.input-cash__clz {
    top: 147px;
    left: 321px;
    width: 218px;
    height: 27px;
    text-align: left;
    font: normal normal 600 20px/24px Segoe UI;
    color: #000000;
    opacity: 1;
    margin-left: 12px;
}

.spinnerpercent ::before {
    z-index: 100000;
    content: "%";
    position: absolute;
    right: 49px;
    top: 10px;
    font-size: 15px;
}

.numInput__clz {
    width: 130px;
    height: 30px;
    text-align: center;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    background-color: #ffffffbf !important;
    border-radius: unset;
    padding: 0.5rem 1rem;
    padding-bottom: 0.5rem;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #ffffff;
}

.menu-wrapper-nav-link__clz {
    color: white;
    width: auto !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
li.menu-wrapper-items-nav-link-active__clz.active {
    background: #f8f9faa3;
    width: 100%;
}
a.menu-wrapper-items-nav-link-active__clz.active {
    color: whitesmoke !important;
    opacity: 1 !important;
    border-left: 2px solid whitesmoke !important;
}
.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.active-tab__clz {
    border-bottom: 2px solid #000;
}

.style-table__clz {
    display: grid;
    min-height: 650px;
    grid-template-columns: auto;
    grid-column-gap: 4px;
    border: 0;
}
.modal-wrapper__clz .modal-content {
    height: 92vh !important;
    width: 95vw !important;
}
.modal-wrapper-width__clz .modal-content {
    width: 95vw !important;
}


.style-table__clz {
    display: grid;
    min-height: 650px;
    grid-template-columns: auto;
    grid-column-gap: 4px;
    border: 0;
}
.modal-wrapper__clz .modal-content {
    height: 92vh !important;
    width: 95vw !important;
}
.modal-wrapper-width__clz .modal-content {
    width: 95vw !important;
}


.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}

input[type="file"] {
    display: none;
}

.file-uploader__clz {
    border: none;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.menu-wrapper-nav-link__clz {
    color: white;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 190px;
    height: 44px;
    text-align: justify;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: unset !important;
    border-color: unset !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}

.border-footer-orb__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 7px !important;
}

.menu-configuration_orb__clz:hover {
    background-color: #ffffff5e;
    border-left: solid;
    border-left-color: currentcolor;
    border-color: white;
}

.rotate__clz {
    transform: rotate(180deg) !important;
}

.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.not-allowed-posEditor__clz {
    pointer-events: none;
    opacity: 0.6;
}

.css-1wa3eu0-placeholder {
    font-size: 14px;
}

.width__clz {
    width: 150px;
}

.new-password-toggle_posEditor__clz {
    position: absolute;
    padding-top: 7px;
    right: 78px;
    opacity: 0.6;
}

input.ant-input:-ms-input-placeholder {
    color: #6c757d !important;
    opacity: 1;
}

input.ant-input::placeholder {
    color: #6c757d !important;
    opacity: 1;
}



.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}

.MuiBox-root.css-0 {
    max-height: calc(100vh - 631px) !important;
}

.checkbox_fiscalYear__clz {
    width: 20px;
    height: 20px;
    accent-color: black;
}

.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
.information_posEditor__clz {
    width: 551px !important;
    height: 625px !important;
}
.information_posEditor_modal__clz {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    width: 0px !important;
    pointer-events: none !important;
    background-color: transparent !important;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
    margin-left: 709px;
    margin-right: 516px;
    margin-bottom: 0px;
}
.not-allowed-posEditor__clz {
    pointer-events: none;
    opacity: 0.8;
    background-color: white !important;
}

